import { _removeLinkedListItem } from "gsap/gsap-core";
import { useEffect, useState } from "react";
import { geolocated } from "react-geolocated";

const LocationSlideGPS = (props) => {
  const [coordinates, setCoordinates] = useState(null);

  const {
    coords,
    timestamp,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
    nextStep,
    text,
  } = props;

  const onError = () => {
    alert("could not load location");
  };

  // useEffect(() => {
  //   if (!isGeolocationAvailable || (isGeolocationEnabled && coords)) {
  //     setTimeout(() => {
  //       nextStep();
  //     }, 1500);
  //   }
  // }, [isGeolocationEnabled, isGeolocationAvailable, coords]);
  return (
    <div>
      <div className="slide">
        {text}
        {!isGeolocationAvailable ? (
          <div>
            Ik kan geen GPS vinden op je telefoon. Even zien welke netwerken ik
            zie... Dat is iets minder nauwkeurig maar dat werkt ook wel.
          </div>
        ) : !isGeolocationEnabled ? (
          <div></div>
        ) : coords ? (
          <div>
            <br />
            <div style={{ fontSize: "1em" }}>
              <span className="coordinate">{coords.latitude}</span>,{" "}
              <span className="coordinate">{coords.longitude}</span>
            </div>
            <br />
            Ah daar zit je! Hebbes.
            <br />
            <div className="button">
              <span onClick={nextStep}>Ok, we gaan verder!</span>
            </div>
            {/* {coords.altitude ? (
              <span>
                , approximately {coords.altitude} meters above sea level
              </span>
            ) : null} */}
          </div>
        ) : null}
        {!!positionError && (
          <div>
            <br />
            Ik mag je GPS niet gebruiken? Dan maar even kijken welke netwerken
            ik zie... Dat is iets minder nauwkeurig maar dat werkt ook wel.
            <div className="button">
              <span onClick={nextStep}>Ok!</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  isOptimisticGeolocationEnabled: false,
  userDecisionTimeout: 5000,
})(LocationSlideGPS);
