const TextInput = ({
  input,
  button,
  size = "small",
  userdata,
  nextStep,
  updateUserData,
}) => {
  if (size === "small") {
    return (
      <>
        <input
          type="text"
          name={input.name}
          // autoFocus
          onChange={updateUserData}
          autoComplete="off"
          placeholder="type hier je antwoord"
        />

        {userdata[input.name] && userdata[input.name].length >= input.minSize && (
          <div className="button">
            <span onClick={nextStep}>{button}</span>
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <textarea
        name={input.name}
        // autoFocus
        onChange={updateUserData}
        autoComplete="off"
        rows="6"
        placeholder="type hier je antwoord"
        key={`textarea-${input.name}`}
      />

      {userdata[input.name] && userdata[input.name].length >= input.minSize && (
        <div className="button">
          <span onClick={nextStep}>{button}</span>
        </div>
      )}
    </>
  );
};

export default TextInput;
