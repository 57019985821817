import { useEffect, useState } from "react";
import LocationSlideGPS from "./GPSlocation";

const LocationSlide = ({ nextStep, text }) => {
  const [loadGPS, setLoadGPS] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoadGPS(true);
    }, 1500);
  }, []);
  if (!loadGPS) {
    return (
      <>
        <div className="sensorsActive" />
        <div className="slide">{text}</div>
      </>
    );
  }
  return (
    <>
      <div className="sensorsActive" />
      <LocationSlideGPS nextStep={nextStep} text={text} />
    </>
  );
};

export default LocationSlide;
