import React, { useEffect, useState } from "react";
import axios from "axios";
const EndSlide = ({ userdata, userPhotos, text }) => {
  useEffect(() => {
    axios
      .post("https://www.denatuurlijkegids.nl/upload/upload.php", userdata)
      .then((response) => uploadPhotos());
  });

  const uploadPhotos = () => {
    axios
      .post(
        "https://www.denatuurlijkegids.nl/upload/upload.php?photos",
        userPhotos
      )
      .then((response) => {
        try {
          localStorage.clear();
        } catch {
          console.log("foutekje");
        }
      });
  };
  return (
    <div>
      <p dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};
export default EndSlide;
