import { useRef, useCallback, useEffect, useState } from "react";
import Webcam from "react-webcam";
import ClickNHold from "react-click-n-hold";
import { gsap } from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";

gsap.registerPlugin(DrawSVGPlugin);

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "environment",
};

const WebcamComponent = ({ nextStep }) => {
  const webcamRef = useRef(null);
  const activeCircle = useRef(null);

  const [finished, setFinished] = useState(false);
  const [holdingStatus, setHoldingStatus] = useState("idle");

  // const handleOrientation = (event) => {
  //   const { absolute, alpha, beta, gamma } = event;
  //   console.log(event);
  // };

  const startHold = (e) => {
    gsap.fromTo(
      activeCircle.current,
      10,
      { drawSVG: "0%" },
      {
        drawSVG: "100%",
        ease: "none",
        onComplete: () => {
          setFinished(true);
          setTimeout(() => {
            nextStep();
          }, 2000);
        },
      }
    );
  };
  const clickNHold = () => {
    /*doet niks*/
  };
  const endHold = () => {
    gsap.killTweensOf(activeCircle.current);
    gsap.to(activeCircle.current, 0.5, {
      drawSVG: "0%",
      onComplete: () => {
        alert(
          "Geen volledige scan kunnen uitvoeren. Probeer het opnieuw. Houdt hierbij je vinger tijdens het draaien op het scherm totdat de cirkel helemaal vol is."
        );
      },
    });
  };
  useEffect(() => {
    gsap.set(activeCircle.current, {
      display: "block",
      drawSVG: "0%",
      // rotate: -90,
    });

    // if (
    //   window.DeviceOrientationEvent ||
    //   window.OrientationEvent ||
    //   typeof window.onorientationchange != "undefined"
    // ) {
    //   window.addEventListener("deviceorientation", handleOrientation, true);
    // }

    // return () => {
    //   // window.removeEventListener("mousemove", () => {});
    // };
  }, []);

  return (
    <div className="webcam">
      <div className="sensorsActive" />

      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
      />
      {!finished && (
        <>
          <svg className="overlayCircle" x="0px" y="0px" viewBox="0 0 200 200">
            <circle cx="100" cy="100" r="84.2" />
            <circle
              ref={activeCircle}
              className="activeCircle"
              cx="100"
              cy="100"
              r="84.2"
            />
          </svg>
          <div className="overlayImage">
            <img src="img/1.png" />
          </div>
          <div className="overlayText">
            <div>
              Klik en blijf het scherm vasthouden.
              <br />
              Draai dan rondjes tot de cirkel vol is.
            </div>
          </div>

          <ClickNHold
            time={5} // Time to keep pressing. Default is 2
            onStart={startHold} // Start callback
            onClickNHold={clickNHold} //Timeout callback
            onEnd={endHold}
          >
            <div
              className={`holdOverlay ${
                holdingStatus === "isHolding" ? "isHolding" : ""
              }`}
            ></div>
          </ClickNHold>
        </>
      )}
      {finished && <div className={`holdOverlay`}>calculating...</div>}
    </div>
  );
};
export default WebcamComponent;
