import React, { useState, useRef, useEffect } from "react";

import WebcamComponent from "./components/webcam";
import Camera from "./components/camera";
import TextInput from "./components/textInput";
import Location from "./components/location";
import Timer from "./components/timer";
import EndSlide from "./components/endslide";
import AdminBar from "./components/admin";
import Logo from "./img/yugo-logo.svg";
import slidesJSON from "./json/slides.json";

import "./App.css";

function App() {
  const { slides } = slidesJSON;
  const scrollElement = useRef();

  const [step, setStep] = useState(0);
  const [userdata, setUserData] = useState({});
  const [userPhotos, setUserPhotos] = useState({});

  const writeToLocalStorage = () => {
    try {
      const combinedData = {
        data: userdata,
        photos: userPhotos,
        step: step + 1,
      };
      localStorage.setItem(
        `panda`,
        JSON.stringify({ timestamp: new Date().getTime(), data: combinedData })
      );

      return true;
    } catch (err) {
      console.log("error: ", err);
      return false;
    }
  };

  const loadDataFromLocalStorage = () => {
    try {
      const { data } = JSON.parse(localStorage.getItem(`panda`), null, -1);
      setUserData(data.data);
      setUserPhotos(data.photos);
      setStep(data.step);
      const now = new Date().getTime();
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    loadDataFromLocalStorage();
  }, []);
  const nextStep = () => {
    writeToLocalStorage();
    setStep(step + 1);
    setTimeout(() => {
      window.scrollTo(0, 0);
      scrollElement.current.scrollTop = 0;
    }, 50);
  };
  const updateUserData = (e) => {
    setUserData({ ...userdata, [e.currentTarget.name]: e.currentTarget.value });
  };

  const renderText = (text) => {
    var renderedText = text;
    Object.keys(userdata).forEach((userVar) => {
      renderedText = renderedText.replaceAll(
        `[${userVar.toUpperCase()}]`,
        userdata[userVar]
      );
    });

    return <p dangerouslySetInnerHTML={{ __html: renderedText }} />;
  };

  const renderSlider = (slider, button) => {
    return (
      <div className="slider">
        {slider.options.map((option, index) => (
          <div
            onClick={(option) => {
              setUserData({
                ...userdata,
                [slider.name]: slider.options[index],
              });
              nextStep();
            }}
            className="option"
            key={`option-${step}-${index}`}
            data-option={option}
          >
            <span>{option}</span>
          </div>
        ))}
      </div>
    );
  };
  const renderTimer = (timer, button) => {
    return (
      <div className="timer">
        <Timer
          initialMinutes={timer.minutes}
          nextStep={nextStep}
          button={button}
        />
      </div>
    );
  };

  const renderStep = () => {
    if (slides[step] && slides[step].alternative) {
      switch (slides[step].alternative) {
        case "end":
          return (
            <EndSlide
              userdata={userdata}
              userPhotos={userPhotos}
              text={slides[step].text}
            />
          );
        case "slider":
          return (
            <div>
              <div className="slide">
                <div className="slider">
                  {slides[step].options.map((option, index) => (
                    <div className="option" key={`option-${step}-${index}`}>
                      <span>{option}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        case "home":
          return (
            <div>
              <div className="slide">
                Hallo,
                <br />
                ik ben <img className="inlinelogo" src={Logo} />.
                <br />
                Ik ben jouw Natuurlijke Gids.
                <br />
                <div className="button" style={{ fontSize: "1em" }}>
                  <span onClick={nextStep}>Aangenaam Yugo</span>
                </div>
              </div>
            </div>
          );
        case "camera":
          return (
            <Camera
              key={`camera-${step}`}
              nextStep={nextStep}
              text={slides[step].text}
              text2={slides[step].text2}
              userdata={userdata}
              updateUserData={updateUserData}
              inputtext={slides[step].inputtext}
              button={slides[step].button}
              setUserPhotos={setUserPhotos}
              userPhotos={userPhotos}
              photoID={slides[step].photoID}
            />
          );
        case "locatie laden":
          return <Location nextStep={nextStep} text={slides[step].text} />;
        case "circel-beweging":
          return <WebcamComponent nextStep={nextStep} />;
      }
    } else if (slides[step]) {
      const {
        text,
        image,
        input,
        inputtext,
        button,
        slider,
        timer,
        animateSensorsActive,
      } = slides[step];
      return (
        <div>
          {animateSensorsActive ? <div className="sensorsActive" /> : null}
          <div className="slide">
            {text ? renderText(text) : null}
            {image ? <img src={`img/${image}`} /> : null}
            {input ? (
              <TextInput
                input={input}
                userdata={userdata}
                nextStep={nextStep}
                updateUserData={updateUserData}
                inputtext={inputtext}
                button={button}
                size="small"
              />
            ) : null}
            {inputtext ? (
              <TextInput
                input={inputtext}
                userdata={userdata}
                nextStep={nextStep}
                updateUserData={updateUserData}
                inputtext={inputtext}
                button={button}
                size="large"
              />
            ) : null}
            {slider ? renderSlider(slider, button) : null}
            {timer ? renderTimer(timer, button) : null}
            {!input && !inputtext && !slider && !timer ? (
              <div className="button">
                <span onClick={nextStep}>{button}</span>
              </div>
            ) : null}
          </div>
        </div>
      );
    }
    return (
      <div>
        <div className="slide">Slide #{step} moet nog gemaakt.</div>
        {slides[step + 1] ? (
          <div className="button">
            <span onClick={nextStep}>Volgende stap</span>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  };
  return (
    <div className="App" ref={scrollElement}>
      <main>
        {renderStep()}
        {/* <pre style={{ fontSize: "12px" }}>{JSON.stringify(userdata)}</pre> */}
        {/* <pre style={{ fontSize: "12px" }}>{JSON.stringify(userPhotos)}</pre> */}
      </main>
      {/* <AdminBar nextStep={nextStep} /> */}
    </div>
  );
}

export default App;
