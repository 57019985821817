import React, { useEffect, useState } from "react";
// import useSound from "use-sound";
// import boopSfx from "../sounds/alarm.wav";

const Timer = ({ initialMinutes, nextStep, button }) => {
  const [minutes, setMinutes] = useState(initialMinutes);
  const [seconds, setSeconds] = useState(0);
  // const [timerSound, { stopTimerSound }] = useSound(boopSfx);

  useEffect(() => {
    let countDownInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          // timerSound();

          clearInterval(countDownInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(countDownInterval);
    };
  });

  return (
    <div>
      {minutes === 0 && seconds === 0 ? (
        <div className="button">
          <span
            onClick={() => {
              nextStep();
              // stopTimerSound();
            }}
          >
            {button}
          </span>
        </div>
      ) : (
        <h1 className="timer">
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </h1>
      )}
    </div>
  );
};
export default Timer;
