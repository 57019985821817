import { useRef, useCallback, useEffect, useState } from "react";
import Webcam from "react-webcam";
import TextInput from "./textInput";

const cameraConstraints = {
  width: 1280,
  height: 720,
  aspectRatio: 1,
  facingMode: "environment",
};

const Camera = ({
  nextstep,
  text,
  text2,
  userdata,
  nextStep,
  updateUserData,
  inputtext,
  button,
  photoID,
  setUserPhotos,
  userPhotos,
}) => {
  const [pictureIsMade, setPictureIsMade] = useState(false);
  const [base64Img, setBase64Img] = useState("niks");
  const cameraRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = cameraRef.current.getScreenshot();
    setBase64Img(imageSrc);
    setPictureIsMade(true);

    setUserPhotos({ ...userPhotos, ["photo-" + photoID]: imageSrc });
  }, [cameraRef]);
  return (
    <div>
      <p dangerouslySetInnerHTML={{ __html: text }} />
      {!pictureIsMade ? (
        <div className="camera">
          <Webcam
            audio={false}
            ref={cameraRef}
            screenshotFormat="image/jpeg"
            videoConstraints={cameraConstraints}
          />
          <div
            className="button"
            onClick={() => {
              capture();
            }}
          >
            Klik
          </div>
        </div>
      ) : (
        <>
          <div className="previewImage">
            <img src={base64Img} />
            <div
              className="button"
              onClick={() => {
                setPictureIsMade(null);
              }}
            >
              <svg style={{ width: "100%" }} viewBox="0 0 512 512">
                <path
                  fill="currentColor"
                  d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"
                ></path>
              </svg>
            </div>
          </div>

          <p dangerouslySetInnerHTML={{ __html: text2 }} />

          <TextInput
            input={inputtext}
            userdata={userdata}
            nextStep={nextStep}
            updateUserData={updateUserData}
            inputtext={inputtext}
            button={button}
            size="large"
          />
        </>
      )}
    </div>
  );
};
export default Camera;
